import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import Loader from 'ToolboxComponents/commons/loader/loader';
import React, { useContext, useEffect, useState  } from 'react';
import { AccountContext, LanguageContext, WhiteLabelContext, GeolocationContext } from "ToolboxUtils/web/context/context";
import ClusterView from 'ToolboxComponents/webapp/pages/cluster-view/cluster-view';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import { Helmet } from "react-helmet";
import getMetaDescription from 'ToolboxUtils/web/get-meta-description';
import slugify from 'url-slug';


const Incontournable = props => {
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [coordinates] = useContext(GeolocationContext).usePath('coordinates');
  const [account, setAccount] = useContext(AccountContext).usePath();

  const products = useApiChuchoteurs.get(`/products`, {
    params: { 
      filter: 'incontournables', 
      language: language, 
      whiteLabelId: whiteLabel.id,
      isInZone: whiteLabel.isInZone,
      longitude: coordinates.longitude,
      latitude: coordinates.latitude
    }
  });


  const incontournables = {
    title: {
      fr: "Nos incontournables"
    },
    teaser: {
      fr: whiteLabel.id === 10 ?
      `Immanquables et inspirants, ils ne vous donneront qu'une envie, re(découvrir) les Vals de Saintonge.`
      : `Immanquables et inspirants, ils ne vous donneront qu'une envie, re(découvrir) la Charente Limousine.`
    }
  }
  const cluster = {
    title: incontournables.title.fr,
    teaser: incontournables.teaser.fr,
  }


  useIsContentLoaded(products.isComplete);

  const openSignInModal = status => {
    setSignIn({ opened: true });
  }

  const updateFav = id => {
    if (account.favorites.includes(id)) {
      const favorites = account.favorites.filter(favorite => favorite !== id);
      setAccount('favorites', favorites);
    } else {
      const favorites = account.favorites.concat([id]);
      setAccount('favorites', favorites);
    }
  }

  
  return (
    

     <>
     {products.isComplete  
        ? 
        <>
        <Helmet>
          <title>{cluster.title} - {whiteLabel.name}</title>
          <meta name="description" content={getMetaDescription(cluster.teaser)}/>
          <link rel="canonical" href={'https://' + window.location.hostname + (whiteLabel.path ? '/' + whiteLabel.path : '') + props.page.path[language] + `/${slugify(cluster.title, {lower: true})}`} />
        </Helmet>
        <ClusterView
            cluster= {cluster}
            products={products.data}
            addFavorite={async (id) => {
              if (account && account.id) {
                try {
                  const favorite = await apiChuchoteurs.post(`/accounts/${account.id}/products/${id}/favorites`, {
                    token: localStorage.token
                  })
                  updateFav(id);
                } catch(err) {
                  console.log(err);
                }
              } else {
                openSignInModal(true);
              }
            }}
            deleteFavorite={async (id) => {
              if (account && account.id) {
                try {
                  const favorite = await apiChuchoteurs.delete(`/accounts/${account.id}/products/${id}/favorites`, {
                    token: localStorage.token
                  })
                  updateFav(id);
                } catch(err) {
                  console.log(err);
                }
              } else {
                openSignInModal(true);
              }
            }} 
          />
          </>
        : <Loader />
      }
    </>
     
     
  )

    
     
}
  


export default Incontournable;
